@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@100;300;400;600;700&display=swap');


// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #b71234;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6e0c1f;
  --ion-color-primary-tint: #df3456;

  /** secondary **/
  --ion-color-secondary: #083c7a;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;


  /** showreport **/
  --ion-color-eventslight: #083c7a;
  --ion-color-eventslight-rgb: 61, 194, 255;
  --ion-color-eventslight-contrast: #ffffff;
  --ion-color-eventslight-contrast-rgb: 255, 255, 255;
  --ion-color-eventslight-shade: #36abe0;
  --ion-color-eventslight-tint: #50c8ff;

  .ion-color-eventslight {
    --ion-color-base: var(--ion-color-eventslight);
    --ion-color-base-rgb: var(--ion-color-eventslight-rgb);
    --ion-color-contrast: var(--ion-color-eventslight-contrast);
    --ion-color-contrast-rgb: var(--ion-color-eventslight-contrast-rgb);
    --ion-color-shade: var(--ion-color-eventslight-shade);
    --ion-color-tint: var(--ion-color-eventslight-tint);
  }

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #fff;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #fff;
  --ion-color-dark-tint: #fff;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  //--ff-header: 'Comic Sans MS', sans-serif; // Only for testing because it is REALLY obvious
  --ff-header: 'Roboto', sans-serif;
  --ff-body: 'Lato', sans-serif;


  // Font sizes
  --fs-800: 1.375rem;
  --fs-700: 1.25rem;
  --fs-600: 1.125rem;
  --fs-500: 1.0625rem;
  --fs-400: 1rem;
  --fs-300: 0.875rem;
  --fs-200: 0.75rem;
  --fs-100: 0.625rem;

  // Margins and Paddings
  --margin-400: 1rem;
  --padding-400: var(--margin-400);

}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #b71234;
//     --ion-color-primary-rgb: 56, 128, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #6e0c1f;
//     --ion-color-primary-tint: #df3456;

//     /** secondary **/
//     --ion-color-secondary: #083c7a;
//     --ion-color-secondary-rgb: 61, 194, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #36abe0;
//     --ion-color-secondary-tint: #50c8ff;

//     /** tertiary **/
//     --ion-color-tertiary: #5260ff;
//     --ion-color-tertiary-rgb: 82, 96, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #4854e0;
//     --ion-color-tertiary-tint: #6370ff;

//     /** success **/
//     --ion-color-success: #2dd36f;
//     --ion-color-success-rgb: 45, 211, 111;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 255, 255, 255;
//     --ion-color-success-shade: #28ba62;
//     --ion-color-success-tint: #42d77d;

//     /** warning **/
//     --ion-color-warning: #ffc409;
//     --ion-color-warning-rgb: 255, 196, 9;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0ac08;
//     --ion-color-warning-tint: #ffca22;

//     /** danger **/
//     --ion-color-danger: #eb445a;
//     --ion-color-danger-rgb: 235, 68, 90;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #cf3c4f;
//     --ion-color-danger-tint: #ed576b;

//     /** dark **/
//     --ion-color-dark: #fff;
//     --ion-color-dark-rgb: 34, 36, 40;
//     --ion-color-dark-contrast: #ffffff;
//     --ion-color-dark-contrast-rgb: 255, 255, 255;
//     --ion-color-dark-shade: #fff;
//     --ion-color-dark-tint: #383a3e;

//     /** medium **/
//     --ion-color-medium: #92949c;
//     --ion-color-medium-rgb: 146, 148, 156;
//     --ion-color-medium-contrast: #ffffff;
//     --ion-color-medium-contrast-rgb: 255, 255, 255;
//     --ion-color-medium-shade: #808289;
//     --ion-color-medium-tint: #9d9fa6;

//     /** light **/
//     --ion-color-light: #f4f5f8;
//     --ion-color-light-rgb: 244, 245, 248;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0, 0, 0;
//     --ion-color-light-shade: #d7d8da;
//     --ion-color-light-tint: #f5f6f9;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #fff;
//     --ion-background-color-rgb: 0255, 0255, 0255;

//     --ion-text-color: #000;
//     --ion-text-color-rgb: 0, 0, 0;

//     --ion-color-step-50: #fff;
//     --ion-color-step-100: #fff;
//     --ion-color-step-150: #fff;
//     --ion-color-step-200: #fff;
//     --ion-color-step-250: #fff;
//     --ion-color-step-300: #fff;
//     --ion-color-step-350: #fff;
//     --ion-color-step-400: #fff;
//     --ion-color-step-450: #fff;
//     --ion-color-step-500: #fff;
//     --ion-color-step-550: #fff;
//     --ion-color-step-600: #fff;
//     --ion-color-step-650: #fff;
//     --ion-color-step-700: #fff;
//     --ion-color-step-750: #fff;
//     --ion-color-step-800: #fff;
//     --ion-color-step-850: #fff;
//     --ion-color-step-900: #fff;
//     --ion-color-step-950: #fff;

//     --ion-item-background: #fff;

//     --ion-card-background: #fff;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 255, 255, 255;

//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0, 0, 0;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #fff;
//     --ion-color-step-100: #fff;
//     --ion-color-step-150: #fff;
//     --ion-color-step-200: #fff;
//     --ion-color-step-250: #fff;
//     --ion-color-step-300: #fff;
//     --ion-color-step-350: #fff;
//     --ion-color-step-400: #fff;
//     --ion-color-step-450: #fff;
//     --ion-color-step-500: #fff;
//     --ion-color-step-550: #fff;
//     --ion-color-step-600: #fff;
//     --ion-color-step-650: #fff;
//     --ion-color-step-700: #fff;
//     --ion-color-step-750: #fff;
//     --ion-color-step-800: #fff;
//     --ion-color-step-850: #fff;
//     --ion-color-step-900: #fff;
//     --ion-color-step-950: #fff;

//     --ion-item-background: #fff;

//     --ion-toolbar-background: #fff;

//     --ion-tab-bar-background: #fff;

//     --ion-card-background: #fff;
//   }
// }
