/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


body {
  font-family: var(--ff-body);
  font-size: var(--fs-400);
}

ion-item {
  font-size: var(--fs-400);
}

ion-loading.secondary-loading {
  //--background: #e3edff;
  --spinner-color: var(--ion-color-secondary);

  //color: #1c6dff;
}

.list-item {


  .list-item-datum {
    font-size: 0.8em;
    color: #ff0000;
  }
}

.secondaryAlert {
  button.alert-button {
    color: var(--ion-color-secondary);
  }

}

.action-sheet-eventslight {

  --button-color: var(--ion-color-eventslight);

}

.alert-eventslight {

  button.alert-button {

    color: var(--ion-color-eventslight);
  }


}

ion-card.picture-card {
  background: white;
  border: 2px dashed silver;
  border-radius: 0;
  aspect-ratio: 1;
  margin: 0.25rem;
}

ion-card.uploadfield {
  background: white;
  border: 2px dashed silver;
  border-radius: 0;
  aspect-ratio: 1;
  margin: 0.25rem;
}

.ratio {
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  width: 100%;
}

.ratio-1x1 {
  padding-bottom: 100%;
}

.position-absolute {
  position: absolute;
}

.top-50 {
  top: 50%;
}

.start-50 {
  left: 50%;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.w-100p {
  width: 100%;
}
